<template>
	<section class="news blog_part min-height-80vh">
        <div class="container">
            <div class="breadcrump">
                <h4 class="py-3 mb-4"><span class="text-muted fw-light"><a href="/">{{ $j('Homepage') }}</a> <i class="mdi mdi-chevron-right"></i></span> {{ $j('News') }}</h4>
            </div>
            <div class="section-title text-center">
                <p>{{ $j('News') }}</p>
                <h2 class="mb-4"></h2>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-3 col-xl-3" v-for="(item, value) in news" :key="value">
                    <div class="single-home-blog">
                        <div class="card">
                            <img :src="$store.state.mainBaseUrl + item.filePath" class="card-img-top" alt="">
                            <div class="card-body">
                                <!-- <a href="blog.html">Technology</a> | <span> March 30, 2019</span> -->
                                <a :href="`/news/detail/` + item.id"><h5 class="card-title">{{ item.titleUz }}</h5></a>
                                <ul class="d-flex justify-content-between">
                                    <li> <span class="ti-calendar"></span>{{ parseDate(item.createdDate) }}</li>
                                    <li> <span class="ti-eye"></span>{{ item.readCount }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-3 d-flex justify-content-center" v-if="getPage > 1">
                <paginate 
                    v-model="getPage"
                    :page-count="pages"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="clickCallback"
                    :prev-text="'＜'"
                    :next-text="'＞'"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </paginate>
                <form @submit.prevent="clickCallback(getPage)" >
                    <input v-model="getPage" type="number" class="page-input" />
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
    components: {
		Paginate,
	},
    data() {
		return {
            news: [],

            limit: 10,
			offset: 0,
			pages: 0,
			getPage: 1,
        };
	},
	methods: {
        getData() {
			this.$api.get("/query_service/api/v1/news/get_news?" + 
					"offset=" + this.offset +
					"&limit=" + this.limit).then(response => {
				this.news = response.result.data;
                this.pages = Math.ceil(response.result.count / this.limit);
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
        parseDate(date) {
			var parse = '0';
			if(date && date != 0){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		},
        clickCallback(page) {
			this.offset = (page - 1) * this.limit
			this.getData()
		},
	},
	mounted() {
        this.getData();
	}
}
</script>